export let API_ENDPOINT;
export let TYMELINE_BACKEND_API_ENDPOINT = "https://tools.tymeline.app/";
export let LINKEDIN_API_ENDPOINT =
    "https://tymeline-linkedin.azurewebsites.net/";
export let TYMELINE_TOOLS_ENDPOINT =
    "https://tymeline-tools.azurewebsites.net/api/v1/";
const developmentServerEndpoint =
    "a4ee599c4ada74167a545bf193955cf8-709635569.us-east-2.elb.amazonaws.com";
const productionServerEndpoint = "dashboard.tymeline.app";

if (process.env.NODE_ENV === "development") {
    API_ENDPOINT = "https://backendapi.tymeline.app/api/v1/";
} else if (window.location.hostname === developmentServerEndpoint) {
    API_ENDPOINT =
        "http://a3112db0904e74ff3b493a70f1607120-1225928165.us-east-2.elb.amazonaws.com:8000/api/v1/";
} else if (window.location.hostname === productionServerEndpoint) {
    API_ENDPOINT = "https://backendapi.tymeline.app/api/v1/";
}

export const api_requests = {
    account: `${API_ENDPOINT}accm/login/`,
    account_tl: `${API_ENDPOINT}tl/account/login`,
    getOTP: `${API_ENDPOINT}tl/account/generate-otp`,
    getConsent: `${API_ENDPOINT}tl/consent/`,
    forgot_password: `${API_ENDPOINT}accm/reset_password_link/`,
    reset_password: `${API_ENDPOINT}accm/reset_password/`,
    masterLogin: `${API_ENDPOINT}tl/master/login`,
    user: `${API_ENDPOINT}dbu/user/`,
    newUser: `${API_ENDPOINT}external/dbu/create_user/from_invite_link`,
    generateLink: `${API_ENDPOINT}cd/invite_users/generate_link`,
    deleteLink: `${API_ENDPOINT}cd/invite_users/remove_link`,
    userInvite: `${API_ENDPOINT}dbu/userInvite/`,
    user_account: `${API_ENDPOINT}dbu/user/account/`,
    userList: `${API_ENDPOINT}dbu/userlist`,

    role: `${API_ENDPOINT}rc/role/`,
    roleList: `${API_ENDPOINT}rc/rolelist`,

    company: `${API_ENDPOINT}cd/company/`,
    companybasic: `${API_ENDPOINT}cd/company/basic/`,

    associatedCompanyList: `${API_ENDPOINT}tl/{id}/get-business`,

    onboard: `${API_ENDPOINT}hmn/human/create/`,
    onboardList: `${API_ENDPOINT}hmn/humanlist/`,
    onboardDetails: `${API_ENDPOINT}hmn/human/`,
    onboardAccount: `${API_ENDPOINT}hmndt/human-details/`,
    onboardDelete: `${API_ENDPOINT}hmn/human/`,
    multipleOnboardsCreate: `${API_ENDPOINT}hmn/human/create-list/`,
    multipleDateTimeNodeupdate: `${API_ENDPOINT}hmndt/human-details-admin-update-bulk/`,
    singleDateTimeNodeUpdate: `${API_ENDPOINT}hmndt/human-details-admin-update/`,
    onboardDetailsAdmin: `${API_ENDPOINT}hmn/human-admin/`,
    onboardAccountAdmin: `${API_ENDPOINT}hmndt/human-details-admin/`,
    orientation: `${API_ENDPOINT}oc/orientation/`,
    orientationLists: `${API_ENDPOINT}oc/orientationlist/`,

    training: `${API_ENDPOINT}tc/training/`,
    trainingList: `${API_ENDPOINT}tc/traininglist/`,

    subMenu: `${API_ENDPOINT}sbm/sub-menu/`,
    subMenuList: `${API_ENDPOINT}sbm/submenulist/`,

    item: `${API_ENDPOINT}tc/item/`,
    itemList: `${API_ENDPOINT}tc/itemlist`,

    question: `${API_ENDPOINT}qc/question/`,
    courseQuestion: `${API_ENDPOINT}lp/question/`,
    questionList: `${API_ENDPOINT}qc/questionlist/`,

    offerLetter: `${API_ENDPOINT}oflt/offer-letter/`,

    workflowList: `${API_ENDPOINT}wkfl/workflowlist/`,
    generalWorkflowList: `${API_ENDPOINT}general/workflowlist/`,
    workflow: `${API_ENDPOINT}wkfl/workflow/`,

    search: `${API_ENDPOINT}sbm/search/`,
    skillsList: `${API_ENDPOINT}skl/skill-list/`,
    skills: `${API_ENDPOINT}skl/skill/`,
    reports: `${API_ENDPOINT}rpt/reports/`,
    // teamsList: `${API_ENDPOINT}tm/teamlist/`,
    teamsList: `${API_ENDPOINT}tm/teamlist`,
    teamTitle: `${API_ENDPOINT}tm/team`,
    peopleTeamCreate: `${API_ENDPOINT}tm/people_search/team`,
    addTeamMembers: `${API_ENDPOINT}tm/addteamusers/`,
    // team: `${API_ENDPOINT}tm/team/`,
    // teamTitle: `${API_ENDPOINT}/team/${teamId}`,
    // vacancy: `${API_ENDPOINT}jp/vacancy`,
    accept_invite: `${API_ENDPOINT}tm/user/validateandaccept`,
    remove_teamUser: `${API_ENDPOINT}tm/team/removeorleaveteam/`,
    organizationGoal: `${TYMELINE_TOOLS_ENDPOINT}organization-goals`,
    generateTeamGoals: `${TYMELINE_TOOLS_ENDPOINT}organization-goals/{organization_goal_id}/team-goals/generate`,
    createTeamGoal: `${TYMELINE_TOOLS_ENDPOINT}goals/teams`,
    getAllTeamGoals: `${TYMELINE_TOOLS_ENDPOINT}team-goals`,
    teamGoal: `${TYMELINE_TOOLS_ENDPOINT}organization-goals/{organization_goal_id}/team-goals`,
    getAllteamMemberGoals: `${TYMELINE_TOOLS_ENDPOINT}team-member-goals`,
    teamMemberGoals: `${TYMELINE_TOOLS_ENDPOINT}organization-goals/{organization_goal_id}/team-goals/{team_goal_id}/team-member-goals`,
    teamMemberGoalById: `${TYMELINE_TOOLS_ENDPOINT}organization-goals/{organization_goal_id}/team-goals/{team_goal_id}/team-member-goals/{team_member_goal_id}`,
    teamGoalsList: `${TYMELINE_TOOLS_ENDPOINT}goals/teams/`,
    generateLinkToken: `${TYMELINE_TOOLS_ENDPOINT}integrations/merge/link-token/generate`,
    savePublicToken: `${TYMELINE_TOOLS_ENDPOINT}integrations/merge/public-token/verify`,
    syncMerge: `${TYMELINE_TOOLS_ENDPOINT}integrations/{integration_id}/merge/sync`,
    revokeLicence: `${TYMELINE_TOOLS_ENDPOINT}licenses/revoke`,
    revokeTeamMember: `${TYMELINE_TOOLS_ENDPOINT}integrations/teams/members`,
    reassignLicense: `${TYMELINE_TOOLS_ENDPOINT}licenses/assign`,
    getTeamMembers: `${TYMELINE_TOOLS_ENDPOINT}integrations/merge/teams`,
    getNewTeamMembers: `${TYMELINE_TOOLS_ENDPOINT}integrations/{integration_id}/merge/teams`,
    getTeamMembersNew: `${TYMELINE_TOOLS_ENDPOINT}integrations/teams`,
    inviteUser: `${API_ENDPOINT}dbu/invite_user`,
    getIntegrationsList: `${API_ENDPOINT}cd/integrations/list`,
    getIntegratedUsers: `${TYMELINE_TOOLS_ENDPOINT}integrations/users`,
    onboardTeams: `${API_ENDPOINT}tm/onboard/teams`,
    onboardTeamsNew: `${TYMELINE_TOOLS_ENDPOINT}integrations/teams/members`,
    deleteUser: `${TYMELINE_TOOLS_ENDPOINT}integrations/teams/members`,
    getTradeOffs: `${TYMELINE_TOOLS_ENDPOINT}organization-goals/{organization_goal_id}/tradeoffs`,
    getProjects: `${TYMELINE_TOOLS_ENDPOINT}projects`,
    unifiedSuccess: `${TYMELINE_TOOLS_ENDPOINT}integrations/unified/success`,
    unifiedFailure: `${TYMELINE_TOOLS_ENDPOINT}integrations/unified/failure`,
};
