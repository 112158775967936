import Office from "components/toolkit/Icons/Office";
import Shop from "components/toolkit/Icons/Shop";
import React from "react";
import rookieRoutesConfig from "./rookieRoutesConfig";
import vacancyRoutesConfig from "./vacancyRoutesConfig";
import tymelineRoutesConfig from "./tymelineRoutesConfig";
import HelpIcon from "@mui/icons-material/Help";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import GavelIcon from "@mui/icons-material/Gavel";
// import PageviewIcon from "@mui/icons-material/Pageview";
// import ViewListIcon from "@mui/icons-material/ViewList";
// import SettingsIcon from "@mui/icons-material/Settings";
// import FactCheckIcon from "@mui/icons-material/FactCheck";
import AddCardIcon from "@mui/icons-material/AddCard";

// import DashboardIcon from "@mui/icons-material/Dashboard";
import FlagIcon from "@mui/icons-material/Flag";
import { MdFace, MdSupervisorAccount } from "react-icons/md";
import Diversity3Icon from "@mui/icons-material/Diversity3";
// import GroupWorkIcon from "@mui/icons-material/GroupWork";
// import { BsPersonPlusFill as NominateIcon } from "react-icons/bs";
// import { VerificationIcon } from "components/toolkit/Navbars/menu-vector-icons";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { MEMBER } from "utils/constants";
import { ADMIN } from "utils/constants";

const AddTymelineEventComponent = React.lazy(() =>
    import("pages/Tymeline/AddTymelineEventComponent")
);
// const NominationsPage = React.lazy(() => import("pages/nominateSection"));

// const TymelineOverview = React.lazy(() =>
//     import("pages/Tymeline/TymelineOverview")
// );
// const TymelineSettings = React.lazy(() =>
//     import("pages/Tymeline/TymelineSettings")
// );

// const TymelineValidatePoolPage = React.lazy(() =>
//     import("pages/Tymeline/TymelineValidatePoolComponent")
// );
// const TymelineVerifiyRequestComponent = React.lazy(() =>
//     import("pages/Tymeline/TymelineVerifiyRequestComponent")
// );
// const DashboardNewPage = React.lazy(() => import("pages/DashboardNewPage"));
const DashboardPage = React.lazy(() => import("pages/dashboard"));

// const TymelinePage = React.lazy(() => import("pages/TymelinePage"));
// const DashboardPage = React.lazy(() => import("pages/DashboardPage"));
// const ProfilePage = React.lazy(() => import("pages/profilePage"));
const ProfilePage = React.lazy(() => import("pages/new-profile-page"));
const CompanyPage = React.lazy(() => import("pages/CompanyPage"));
const RolesPage = React.lazy(() => import("pages/RolesPage"));
// const UsersPage = React.lazy(() => import("pages/UsersPage"));
const TeamsPage = React.lazy(() => import("pages/TeamsPage"));
// const TeamsSyncPage = React.lazy(() => import("pages/teams/TeamSyncPage"));
const TeamsSyncPage = React.lazy(() => import("pages/org-members-section"));
const OrganizationGoalsPage = React.lazy(() =>
    import("pages/Organizational Goals/OrganizationGoals")
);
const TeamMemberGoalsPage = React.lazy(() =>
    import("pages/TeamMemberGoals/TeamMemberGoals")
);
const TeamGoalsPage = React.lazy(() => import("pages/Goals/TeamGoals"));
const PeopleSearchPage = React.lazy(() => import("pages/peopleSearch"));
const ProjectsPage = React.lazy(() => import("pages/projects"));
const ViewProjectTemplatesPage = React.lazy(() =>
    import("pages/projects/templates/ViewTemplatesPage")
);

export const DASHBOARD = "User Dashboard";

const routes = [
    {
        path: "/user-dashboard",
        // component: DashboardNewPage,
        component: DashboardPage,
        className: "tymeline_dashboard",
        exact: true,
        type: "collapse",
        name: DASHBOARD,
        icon: <Shop size='12px' />,
        noCollapse: true,
        showInMenu: true, // <- If true will appear in LHS menu
        // role: [], // <- Use this to filter whether user can access this route or not
        noSuperAdmin: true,
        role: [MEMBER, ADMIN],
    },

    {
        type: "collapse",
        name: "Goals",
        icon: <FlagIcon size='12px' />,
        keyName: "goals",
        exact: true,
        showInMenu: true,
        noSuperAdmin: false,
        accessKey: "peopleHub",
        role: [MEMBER, ADMIN],
        collapse: [
            {
                key: "epics",
                adderOnly: true,
                path: "/goals/epics",
                type: "collapse",
                name: "Epics",
                icon: <Diversity3Icon size='12px' />,
                component: OrganizationGoalsPage,
                className: "nominate_candidate",
                exact: true,
                role: [ADMIN],
            },
            {
                key: "get-all-team-goals",
                adderOnly: true,
                path: "/goals/get-all-team-goals",
                type: "collapse",
                name: "Team Goals",
                icon: <Diversity1Icon size='12px' />,
                component: TeamGoalsPage,
                className: "nominate_candidate",
                exact: true,
                role: [MEMBER, ADMIN],
            },
            {
                key: "team-member-goals",
                adderOnly: true,
                path: "/goals/team-member-goals",
                type: "collapse",
                name: "Member Goals",
                icon: <Diversity2Icon size='12px' />,
                component: TeamMemberGoalsPage,
                className: "nominate_candidate",
                exact: true,
                role: [MEMBER, ADMIN],
            },
            // {
            //   key: "sync-teams",
            //   adderOnly: true,
            //   path: "/goals/sync-teams",
            //   type: "collapse",
            //   name: "Sync Team",
            //   icon: <GroupWorkIcon size="12px" />,
            //   component: TeamsSyncPage,
            //   className: "nominate_candidate",
            //   exact: true,
            //   role: [ADMIN],
            // },
        ],
    },
    {
        type: "collapse",
        name: "Projects",
        icon: <ViewKanbanIcon size='12px' />,
        keyName: "projects",
        exact: true,
        showInMenu: true,
        noSuperAdmin: false,
        accessKey: "peopleHub",
        role: [ADMIN],
        collapse: [
            {
                path: "/projects/view-all-projects",
                name: "View Projects",
                key: "view-all-projects",
                component: ProjectsPage,
                icon: <AccountTreeIcon size='12px' />,
                exact: true,
                role: [MEMBER, ADMIN],
            },
            {
                path: "/projects/view-project-templates",
                name: "Templates",
                key: "view-project-templates",
                component: ViewProjectTemplatesPage,
                icon: <GavelIcon size='12px' />,
                exact: true,
                role: [MEMBER, ADMIN],
            },
        ],
    },
    {
        type: "collapse",
        name: "Talent Hub",
        className: "talent-tymeline",
        icon: <MdSupervisorAccount size='12px' />,
        keyName: "talent",
        exact: true,
        showInMenu: true,
        noSuperAdmin: false,
        accessKey: "peopleHub",
        role: [ADMIN],
        collapse: [
            {
                path: "/talent/talent-search",
                name: "Talent Search",
                key: "talent-search",
                component: PeopleSearchPage,
                icon: <PersonSearchIcon size='12px' />,
                exact: true,
                role: [ADMIN],
            },
            {
                name: "Add Record",
                key: "add-event",
                className: "add_record",
                path: "/talent/add-event",
                component: AddTymelineEventComponent,
                icon: <AddCardIcon />,
                exact: true,
                adderOnly: true,
                role: [ADMIN],
            },
            {
                key: "teams",
                adderOnly: true,
                path: "/talent/teams",
                type: "collapse",
                name: "Teams",
                icon: <Diversity3Icon size='12px' />,
                component: TeamsPage,
                className: "nominate_candidate",
                exact: true,
                role: [ADMIN],
            },
            {
                key: "team-members",
                adderOnly: true,
                path: "/talent/team-members",
                type: "collapse",
                name: "Team Members",
                icon: <MdSupervisorAccount size='12px' />,
                component: TeamsSyncPage,
                className: "nominate_candidate",
                exact: true,
                role: [ADMIN],
            },
            // {
            //     path: "/talent/users",
            //     name: "Users",
            //     key: "users",
            //     component: UsersPage,
            //     icon: <MdSupervisorAccount size='12px' />,
            //     exact: true,
            //     role: [ADMIN],
            // },
            {
                path: "/talent/roles",
                key: "roles",
                name: "Roles",
                component: RolesPage,
                icon: <MdFace />,
                exact: true,
                role: [ADMIN],
            },
        ],
    },
    {
        path: "/profilePage",
        component: ProfilePage,
        className: "tymeline_profile",
        exact: true,
        type: "collapse",
        name: "Profile",
        icon: <Shop size='12px' />,
        noCollapse: true,
        // showInMenu: true, // <- If true will appear in LHS menu
        showInMenu: false, // <- If true will appear in LHS menu
        noSuperAdmin: true,
        role: ["default"],
    },
    // {
    //     type: "collapse",
    //     name: "Verification Hub",
    //     className: "tymeline",
    //     icon: <VerificationIcon />,
    //     keyName: "tymeline",
    //     exact: true,
    //     showInMenu: true,
    //     noSuperAdmin: false,
    //     accessKey: "verificationHub",
    //     collapse: [
    //         // Note: Key and path should be same for active class to appear
    //         {
    //             name: "Dashboard",
    //             key: "verification-hub-dashboard",
    //             path: "/tymeline/verification-hub-dashboard",
    //             component: TymelineOverview,
    //             className: "check_tymelinesd",
    //             icon: <DashboardIcon />,
    //             exact: true,
    //             role: "verificationHub",
    //         },
    //         {
    //             name: "Access Profile",
    //             key: "check-tymeline",
    //             path: "/tymeline/check-tymeline",
    //             component: TymelinePage,
    //             className: "check_tymeline",
    //             icon: <PageviewIcon />,
    //             exact: true,
    //             role: "verificationHub",
    //         },

    //         {
    //             key: "nominations",
    //             adderOnly: true,
    //             path: "/tymeline/nominations",
    //             type: "collapse",
    //             name: "Nominations",
    //             icon: <NominateIcon size='12px' />,
    //             component: NominationsPage,
    //             className: "nominate_candidate",
    //             exact: true,
    //             role: "verificationHub",
    //         },
    //         {
    //             name: "Requests",
    //             key: "requests",
    //             icon: <FactCheckIcon />,
    //             collapse: [
    //                 {
    //                     name: "Verifications",
    //                     key: "verification-requests",
    //                     path: "/tymeline/verification-requests",
    //                     component: TymelineVerifiyRequestComponent,
    //                     className: "verification_requests",
    //                     icon: <FactCheckIcon />,
    //                     exact: true,
    //                     role: "verificationHub",
    //                 },
    //                 {
    //                     name: "Validations",
    //                     key: "validate-pool",
    //                     path: "/tymeline/validate-pool",
    //                     component: TymelineValidatePoolPage,
    //                     className: "validate_pool",
    //                     icon: <ViewListIcon />,
    //                     exact: true,
    //                     validatorOnly: true,
    //                     role: "verificationHub",
    //                     // canAccess: (user) => {
    //                     //     return user.roles === 'ABC';
    //                     // }
    //                 },
    //             ],
    //         },

    //         {
    //             name: "Settings",
    //             key: "tymeline-setting",
    //             path: "/tymeline/tymeline-settings",
    //             component: TymelineSettings,
    //             className: "tymeline_settings",
    //             icon: <SettingsIcon />,
    //             exact: true,
    //             role: "verificationHub",
    //         },
    //     ],
    // },

    {
        path: "/company-details",
        type: "collapse",
        // name: "Company Details",
        name: "My Company",
        icon: <Office size='12px' />,
        component: CompanyPage,
        noCollapse: true,
        exact: true,
        showInMenu: true,
        noSuperAdmin: false,
        role: [ADMIN],
    },

    { type: "title", title: "", showInMenu: true },

    {
        type: "title",
        title: "",
        showInMenu: true,
    },

    {
        // path: "/support/view-tickets",
        href: "https://discord.gg/tyNxzgWTPK",
        type: "collapse",
        name: "Support",
        keyName: "support",
        icon: <HelpIcon size='12px' />,
        showInMenu: true,
        noSuperAdmin: true,
        // component: ViewTicketPage,
        noCollapse: true,
        role: ["default"],
    },
];

const finalRoutes = routes.concat(
    rookieRoutesConfig,
    vacancyRoutesConfig,
    tymelineRoutesConfig
);

export const getAuthConfig = (routeName) => {
    return routes.find(({ name }) => name === routeName);
};

export default finalRoutes;
